import React, { useState } from 'react';

import PageWrapper from '../components/PageWrapper';
import { Pages } from '../components/ContentfulContents/contentfulPages';
import { Container } from 'react-bootstrap';
import { Title, Box } from '../components/Core';
import ReactHtmlParser from 'react-html-parser';
import { useStaticQuery } from 'gatsby';

const getCopy = graphql`
  query {
    allCopy: allContentfulCopy {
      edges {
        node {
          id
          copy {
            childMarkdownRemark {
              html
            }
          }
          title
        }
      }
    }
  }
`;

const About = () => {
  const copy = useStaticQuery(getCopy);
  const { aboutUs } = Pages();

  const {
    node: {
      id: pageId,
      sectionTitle,
      copy: {
        childMarkdownRemark: { html: firstParagraph },
      },
      copy2: {
        childMarkdownRemark: { html: secondPara },
      },
      bulletPoints: {
        childMarkdownRemark: { html: bulletPoints },
      },
    },
  } = aboutUs[0];

  const {
    allCopy: { edges },
  } = copy;

  const services = edges.filter(({ node: { id } }) => id === 'd3835982-04c6-549e-bc0c-1d62fa3b4283');

  const demo = edges.filter(({ node: { id } }) => id === '1c144c86-17e0-5866-b671-74c478a90cff');

  return (
    <>
      <PageWrapper>
        <Container>
          <div className="pt-5" />
          <div>
            <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
              {sectionTitle && (
                <>
                  <Title variant="card" key={`${pageId}${sectionTitle}`}>
                    {sectionTitle}
                  </Title>
                </>
              )}
              {firstParagraph && (
                <>
                  <span>{ReactHtmlParser(firstParagraph)}</span>
                </>
              )}
              {secondPara && (
                <>
                  <span>{ReactHtmlParser(secondPara)}</span>
                </>
              )}
              <Box mt={5} />

              {bulletPoints && <>{ReactHtmlParser(bulletPoints)}</>}
              <Box mt={5} />

              {services && <>{ReactHtmlParser(services[0].node.copy.childMarkdownRemark.html)}</>}
              <div>{demo && <>{ReactHtmlParser(demo[0].node.copy.childMarkdownRemark.html)}</>}</div>
            </Box>
          </div>
        </Container>
      </PageWrapper>
    </>
  );
};
export default About;
